import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import BookingHeader from "../../components/booking-header";
import BookingContent from "../../components/booking-content";
import BookingFooter from "../../components/booking-footer";

import { setDate } from "../../reducers/PageBooking/PageBookingActions";
import DateLib from "../../inc/DateLib";
import { setTitle } from "../../inc/lib";
import CONFIG from "../../inc/Config";
import "./styles.css";

import { loadBookingTypes, loadBookingBalanceTypes, loadPaymentStatuses, loadPaymentTypes, 
         loadBookingTimeIds, loadBookingStatuses, loadBookingTimeStatuses } from '../../reducerApi/List';

export default function PageBooking(){
    const _state = useSelector((state) => state, ()=> !CONFIG.isDebug);
    if(CONFIG.isDebug) console.log(_state);
    
    const dispatch = useDispatch();
    const date = useSelector((state) => state.pageBooking.date);
    const params = useParams();
    const dateByUrl = params.date;
    const navigate = useNavigate();
    const nowDate = DateLib.formatterDate(new Date());
    
    useLayoutEffect( () => {
        let _dateByUrl = DateLib.formatterDate(dateByUrl);
        let _date = (date) ? date
            : (_dateByUrl) ? _dateByUrl
            : nowDate;
        let dateTitle = (new Date(_date)).toLocaleString("ru", {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
        batch(() => {
            dispatch(setDate(_date));
            setTitle(dateTitle);
            })
        if(!DateLib.equals(dateByUrl, date))navigate(`/date/${_date}`);
    }, [dispatch, date, nowDate, navigate, dateByUrl]);
    useLayoutEffect( () => {
        if(date){
            let dateTitle = (new Date(date)).toLocaleString("ru", {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
            });
            navigate(`/date/${date}`);
            setTitle(dateTitle);
        }
    }, [date, dateByUrl, navigate]);

    useLayoutEffect(() => {
        loadBookingBalanceTypes({dispatch});
        loadBookingTypes({dispatch});
        loadPaymentTypes({dispatch});
        loadPaymentStatuses({dispatch});
        loadBookingTimeIds({dispatch});
        loadBookingStatuses({dispatch});
        loadBookingTimeStatuses({dispatch});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="page-booking">
        <BookingHeader/>
        <BookingContent/>
        <BookingFooter/>
    </div>
}