const consts = {
    UPDATE_USERS:                   "LIST-UPDATE_USERS",
    UPDATE_ORDERS:                  "LIST-UPDATE_ORDERS",
    UPDATE_PRODUCTS:                "LIST-UPDATE_PRODUCTS",
    UPDATE_PAYMENT_TYPES:           "LIST-PAYMENT_TYPES",
    UPDATE_PAYMENT_STATUSES:        "LIST-PAYMENT_STATUSES",
    UPDATE_BOOKING_TYPES:           "LIST-UPDATE_BOOKING_TYPES",
    UPDATE_BOOKING_STATUSES:        "LIST-UPDATE_BOOKING_STATUSES",
    UPDATE_BOOKING_BALANCE_TYPES:   "LIST-UPDATE_BOOKING_BALANCE_TYPES",
    UPDATE_BOOKING_TIME_IDS:        "LIST-UPDATE_BOOKING_TIME_IDS",
    UPDATE_BOOKING_TIME_STATUSES:   "LIST-UPDATE_BOOKING_TIME_STATUSES",
};
  
export default consts;