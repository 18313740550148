
import ApiBooking from "../api/ApiBooking";

import ApiUsers from "../api/Admin/Users";
import ApiOrders from "../api/Admin/Orders";
import ApiProducts from "../api/Admin/Products";
import ApiPaymentTypes from "../api/Admin/PaymentTypes";
import ApiPaymentStatuses from "../api/Admin/PaymentStatuses";

import { updateUsers, updateBookingTypes, updateBookingBalanceTypes, updateBookingTimeIds,
        updatePaymentStatuses, updatePaymentTypes, updateOrders, updateProducts,
        updateBookingStatuses, updateBookingTimeStatuses } from "../reducers/List/ListActions";

const apiBooking = new ApiBooking({});



export async function loadPaymentTypes ({dispatch}){
    const apiPaymentTypes = ApiPaymentTypes.Index({hidden: 0});
    const data = await apiPaymentTypes.getResponse();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updatePaymentTypes(list));
    }
    return data;
}
export async function loadPaymentStatuses ({dispatch}){
    const apiPaymentStatuses = ApiPaymentStatuses.Index();
    const data = await apiPaymentStatuses.getResponse();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updatePaymentStatuses(list));
    }
    return data;
}

export async function loadUsers ({dispatch , ids = []}){
    const apiUsers = ApiUsers.Index({ids});
    let i = 0;
    let data = [];
    do{
        i++;
        let _data = await apiUsers.getResponseNext();
        if(_data){
            data.concat(_data);
            let list = {};
            _data.forEach(item => list[item.id] = item);
            dispatch(updateUsers(list));
        }
    }while(apiUsers.paginationNext && i < 10);
    return data;
}
export async function loadOrders ({dispatch , ids = []}){
    const apiOrders = ApiOrders.Index({ids});
    const data = await apiOrders.getResponse();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateOrders(list));
    }
    return data;
}
export async function loadProducts ({dispatch , ids = []}){
    const apiProducts = ApiProducts.Index({ids});
    const data = await apiProducts.getResponse();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateProducts(list));
    }
    return data;
}

export async function loadBookingTypes ({dispatch}){
    const data = await apiBooking.getTypes();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateBookingTypes(list));
    }
    return data;
}
export async function loadBookingStatuses ({dispatch}){
    const data = await apiBooking.getStatuses();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateBookingStatuses(list));
    }
    return data;
}
export async function loadBookingTimeStatuses ({dispatch}){
    const data = await apiBooking.getTimeStatuses();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateBookingTimeStatuses(list));
    }
    return data;
}
export async function loadBookingBalanceTypes ({dispatch}){
    const data = await apiBooking.getBalanceTypes();
    if(data){
        let list = {};
        data.forEach(item => list[item.id] = item);
        dispatch(updateBookingBalanceTypes(list));
    }
    return data;
}
export async function loadBookingTimeIds({dispatch}){
    const data = await apiBooking.getTimeIds();
    if(data){
        let list = {};
        data.forEach(item => list[item.timeId] = item);
        dispatch(updateBookingTimeIds(list));
    }
    return data;
}
